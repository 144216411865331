import { useState } from 'react';
import HeaderText from './headerText';
import { Link } from 'react-router-dom';
import { PhoneInput } from 'react-international-phone';
import ErrorIcon from '../assets/error-icon.png';
import 'react-international-phone/style.css';

export default function SignUpMobile() {

    const [phone, setPhone] = useState('');
    // States for checking the errors
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        password: '',
        phone: ''
    });

    const apiUrl = '/api/formdata';

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setSubmitted(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            formData.phone = document.querySelector('input.react-international-phone-input').value + document.getElementById('phone-input').value;

            if (formData.name === '' || formData.password === '' || formData.phone === '' || formData.phone === '+1 ')
                setError(true);

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });


            if (!response.ok) {
                throw new Error('Network response was not ok');
                // setError(true);
            }

            // Handle successful response
            const responseData = await response.json();
            console.log('Response data:', responseData);

            window.localStorage.otpTs = responseData.otpTs;
            window.localStorage.otpHash = responseData.otpHash;
            window.localStorage.otpPhone = responseData.otpPhone;
            if (response.ok && formData.name !== '' && formData.password !== '' && formData.phone !== '' && formData.phone !== '+1 ') {
                window.location.replace("/v?signup=2fa");
                document.querySelector(".errorval").style.display = "none";
            }

        } catch (error) {
            console.error('Error during POST request:', error.message);
            document.querySelector(".errorval").style.display = "block";
            // setError(true);
        }
    };

    // Showing success message
    const successMessage = () => {
        return (
            <div
                className="success"
                style={{
                    display: submitted ? '' : 'none',
                }}>
                <div className="custom-modal">
                    <div className="succes succes-animation icon-top" style={{ backgroundColor: "green" }}><i className="fa fa-check"></i></div>
                    <h1 className="messageSuccess">Success!!</h1>
                </div>
            </div>
        );
    };

    // Showing error message if error is true
    const errorMessage = () => {
        return (
            <div
                className="errorBox mobile"
                style={{
                    display: error ? '' : 'none',
                }}>
                <div className="verifySvg">
                    <img className="error-icon" src={ErrorIcon} alt={"error-icon"} />
                </div>
                <div className="verifyText">
                    Failed. Please try again
                </div>
                <button className="btn" id="closeBtn" onClick={closeBox}>
                    Close
                </button>
            </div>
        );
    };

    const closeBox = () => {
        setError(false);
    };

    window.onclick = e => {
        if (document.querySelector("button.react-international-phone-country-selector-button--active") != null) {
            document.querySelector("foreignObject#svg").style.height = "400px";
            document.querySelector("svg#svg").style.height = "400px";
            document.querySelector("svg#svg").style.marginTop = "-160px";
        }
        else if ((window.location.href.indexOf("signup") > -1) && (document.querySelector("button.react-international-phone-country-selector-button--active") == null)) {
            document.querySelector("foreignObject#svg").style.height = "59px";
            document.querySelector("svg#svg").style.height = "59px";
            document.querySelector("svg#svg").style.marginTop = "0px";
        }
    }


    return (
        <div className="wrapPageMobile">
            <HeaderText />
            <div className="wrapFormTextMobile">
                <div className="wrapperBodyText">
                    <div className="bodyTextSignUp">Experience the Future of Banking
                    </div>
                </div>
            </div >
            <div className="rapForm">
                {/* Calling to the methods */}
                <div className="messages">
                    {errorMessage()}
                    {successMessage()}
                </div>


                <form onSubmit={handleSubmit}>
                    <div className="rapInputsMobile">
                        <div className="headSign">Let’s Get Started</div>
                        <div className="rapSpanSign">
                            <div className="rapSpanSignUp" style={{ borderBottom: "2.5px solid #0360F0", padding: "17px 32px" }}>
                                <span className="signUp" style={{ fontWeight: "800" }}>Sign Up</span>
                            </div>
                            <div className="rapSpanSignIn">
                                <span className="signIn"><Link to="/signin">Sign In</Link></span>
                            </div>
                        </div>

                        <label className="label">Email</label>
                        <svg xmlns="http://www.w3.org/2000/svg" width="321" height="59" viewBox="0 0 361 59" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.82791 1H355.172C357.828 1 360 3.40851 360 6.3532V52.6468C360 55.5915 357.828 58 355.172 58H5.82791C3.17276 58 1 55.5915 1 52.6468V6.3532C1 3.40916 3.17218 1 5.82791 1Z" fill="white" stroke="#8F9CA3" strokeWidth="1.5" />
                            <foreignObject width="321" height="59">
                                <input onChange={handleInputChange} className="input" value={formData.name} type="email" name="name" required />
                            </foreignObject>
                        </svg>

                        <label className="label">Password</label>
                        <svg xmlns="http://www.w3.org/2000/svg" width="321" height="59" viewBox="0 0 361 59" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.82791 1H355.172C357.828 1 360 3.40851 360 6.3532V52.6468C360 55.5915 357.828 58 355.172 58H5.82791C3.17276 58 1 55.5915 1 52.6468V6.3532C1 3.40916 3.17218 1 5.82791 1Z" fill="white" stroke="#8F9CA3" strokeWidth="1.5" />
                            <foreignObject width="321" height="59">
                                <input onChange={handleInputChange} className="input" value={formData.password} type="password" name='password' required />
                            </foreignObject>
                        </svg>

                        <label className="label">Mobile number</label>
                        <svg id="svg" xmlns="http://www.w3.org/2000/svg" width="321" height="59" viewBox="0 0 361 59" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.82791 1H355.172C357.828 1 360 3.40851 360 6.3532V52.6468C360 55.5915 357.828 58 355.172 58H5.82791C3.17276 58 1 55.5915 1 52.6468V6.3532C1 3.40916 3.17218 1 5.82791 1Z" fill="white" stroke="#8F9CA3" strokeWidth="1.5" />
                            <foreignObject id="svg" width="361" height="59">
                                <PhoneInput
                                    defaultCountry="us"
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}
                                />
                                <input className="react-international-phone-input react-international-phone-input-container" id="phone-input" type="tel" name="phone" maxLength="10" placeholder="Enter Your Phone Number" required />
                            </foreignObject>
                        </svg>

                        <div className="errorval">
                            Email already exists. Try another one
                        </div>

                        <div className="rapBtn">
                            <button className="btn" id="submitBtn" type="submit">
                                Sign Up
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div >
    )
}